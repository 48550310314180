import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/account/login.vue"),
    meta: {
      authRequired: false,
      title: "Login",
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in

        /* GET ACTION VUEX STORE */
        let authStore = await store.dispatch("auth/getLogin");
        if (authStore.data !== undefined) {
          // Redirect to the home page instead
          next({ name: "Dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      authRequired: false,
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      authRequired: false,
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      title: "Logout",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
  },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/dashboard/Index"),
  },
  {
    path: "/m-karyawan",
    name: "Karyawan",
    meta: {
      title: "Master Karyawan",
      authRequired: true,
      role: ["SUPER_ADMIN", "DIREKTUR"],
    },
    component: () => import("@/views/m_karyawan/KIndex"),
    children: [
      {
        path: "",
        name: "KaryawanList",
        meta: {
          title: "Kode R&B",
          authRequired: true,
          role: ["SUPER_ADMIN", "DIREKTUR"],
        },
        component: () => import("@/views/m_karyawan/KList"),
      },
      {
        path: "/tambah",
        name: "KaryawanTambah",
        meta: {
          title: "Tambah Karyawan",
          authRequired: true,
          role: ["SUPER_ADMIN", "DIREKTUR"],
        },
        component: () => import("@/views/m_karyawan/KTambah"),
      },
      {
        path: "/edit/:nip",
        name: "KaryawanEdit",
        meta: {
          title: "Edit Karyawan",
          authRequired: true,
          role: ["SUPER_ADMIN", "DIREKTUR"],
        },
        component: () => import("@/views/m_karyawan/KEdit"),
      },
    ],
  },
  {
    path: "/m-lokasi",
    name: "Lokasi",
    meta: { title: "Lokasi", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_lokasi/Index"),
  },
  {
    path: "/m-lembur",
    name: "MLembur",
    meta: { title: "Master Lembur", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_lembur/Index"),
  },
  {
    path: "/m-shift",
    name: "Shift",
    meta: { title: "Shift", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_shift/Index"),
  },
  {
    path: "/m-izin",
    name: "Izin",
    meta: { title: "Izin", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_izin/Index"),
  },
  {
    path: "/m-unit",
    name: "Unit",
    meta: { title: "Unit", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_unit/Index"),
  },
  {
    path: "/on-progress",
    name: "ProgressPresensi",
    meta: {
      title: "Progress Presensi",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/on_progress/Index"),
  },
  {
    path: "/m-jabatan",
    name: "Jabatan",
    meta: { title: "Jabatan", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_jabatan/Index"),
  },
  {
    path: "/presensi",
    name: "Presensi",
    meta: {
      title: "Presensi",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/presensi/Index"),
  },
  {
    path: "/jadwal-ku",
    name: "Jadwalku",
    meta: {
      title: "Jadwalku",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/jadwalku/Index"),
    redirect: "/jadwal-ku/list",
    children: [
      {
        path: "list",
        name: "JadwalKuList",
        meta: {
          title: "JadwalKu",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwalku/List"),
      },
    ],
  },
  {
    path: "/kode-rs",
    name: "codeDaruratRs",
    meta: {
      title: "Kode RS",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/jadwal_code/Index"),
    children: [
      {
        path: "",
        name: "codeDaruratRsList",
        meta: {
          title: "Kode R&B",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal_code/List"),
      },
    ],
  },
  {
    path: "/izin-ku",
    name: "Izinku",
    meta: {
      title: "Izinku",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/izinku/Index"),
  },
  {
    path: "/tukar-shift",
    name: "TukarShift",
    meta: {
      title: "TukarShift",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/tukar_shift/Index"),
  },
  {
    path: "/lembur",
    name: "Lembur",
    meta: {
      title: "Lembur",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/lembur/Index"),
    children: [
      {
        path: "harian",
        name: "HarianLembur",
        meta: {
          title: "Harian Lembur",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/lembur/LemburHarian"),
      },
      {
        path: "pengajuan",
        name: "PengajuanLembur",
        meta: {
          title: "Pengajuan Lembur",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/lembur/LemburTab"),
      },
    ],
  },
  {
    path: "/sppd",
    name: "Sppd",
    meta: {
      title: "Sppd",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/sppd/Index"),
    children: [
      {
        path: "",
        name: "MainSppd",
        meta: {
          title: "List Sppd",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/sppd/List"),
      },
      {
        path: "create",
        name: "CreateSppd",
        meta: {
          title: "Buat Sppd",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/sppd/Create"),
      },
      {
        path: "edit/:id/:nama",
        name: "EditSppd",
        meta: {
          title: "Edit Sppd",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/sppd/Edit"),
      },
    ],
  },
  {
    path: "/jadwal",
    name: "Jadwal",
    meta: {
      title: "Jadwal",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/jadwal/Index"),
    redirect: "/jadwal/list",
    children: [
      {
        path: "list",
        name: "JadwalList",
        meta: {
          title: "Jadwal",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/List"),
      },
      {
        path: "approval",
        name: "ApprovalJadwal",
        meta: {
          title: "Approval Jadwal",
          authRequired: true,
          role: ["SUPER_ADMIN", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/Approval"),
      },
      {
        path: "tambah",
        name: "JadwalTambah",
        meta: {
          title: "Jadwal Tambah",
          authRequired: true,
          role: ["SUPER_ADMIN", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/Tambah"),
      },
      {
        path: "edit/:nip?",
        name: "JadwalEdit",
        meta: {
          title: "Jadwal Edit",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/Edit"),
      },
      {
        path: "codeblue",
        name: "JadwalCodeBlue",
        meta: {
          title: "Jadwal",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/JadwalCodeBlue"),
      },
      {
        path: "codered",
        name: "JadwalCodeRed",
        meta: {
          title: "Jadwal",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/JadwalCodeRed"),
      },
    ],
  },
  {
    path: "/history",
    name: "History",
    meta: {
      title: "history",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
    },
    component: () => import("@/views/history/Index"),
    children: [
      {
        path: "presensi",
        name: "HistoryPresensi",
        meta: {
          title: "History Presensi",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF", "KEPALA", "KASUB", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/history/presensi/Index"),
      },
      {
        path: "tukar-shift",
        name: "HistoryTukarShift",
        meta: {
          title: "History Tukar Shift",
          authRequired: true,
          role: [
            "KEPALA",
            "STAF",
            "SUPER_ADMIN",
            "KEPALA",
            "KABID",
            "KASUB",
            "DIREKTUR",
          ],
        },
        component: () => import("@/views/history/tukar_jadwal/Index"),
      },
      {
        path: "jadwal",
        name: "HistoryJadwal",
        meta: {
          title: "History Jadwal",
          authRequired: true,
          role: ["SUPER_ADMIN", "KABID", "KASUB", "DIREKTUR"],
        },
        component: () => import("@/views/history/jadwal/Index"),
      },
      {
        path: "izin",
        name: "HistoryIzin",
        meta: {
          title: "History Izin",
          authRequired: true,
          role: ["KEPALA", "STAF", "SUPER_ADMIN", "KABID", "KASUB", "DIREKTUR"],
        },
        component: () => import("@/views/history/izin/Index"),
      },
      {
        path: "karyawan",
        name: "HistoryKaryawan",
        meta: {
          title: "History Karyawan",
          authRequired: true,
          role: ["KEPALA", "SUPER_ADMIN", "KABID", "KASUB", "DIREKTUR"],
        },
        component: () => import("@/views/history/karyawan/Index"),
        children: [
          {
            path: "",
            name: "HistoryKaryawanList",
            meta: {
              title: "List Karyawan",
              authRequired: true,
            },
            component: () => import("@/views/history/karyawan/List"),
          },
          {
            path: "kinerja/:nip",
            name: "HistoryKaryawanDetail",
            meta: {
              title: "Detail Kinerja",
              authRequired: true,
            },
            component: () => import("@/views/profil/Main"),
          },
        ],
      },
      {
        path: "lembur",
        name: "HistoryLembur",
        meta: {
          title: "History Lembur",
          authRequired: true,
          role: ["STAF", "KEPALA", "SUPER_ADMIN", "KABID", "KASUB", "DIREKTUR"],
        },
        component: () => import("@/views/history/lembur/Index"),
      },
      {
        path: "sppd",
        name: "HistorySppd",
        meta: {
          title: "History Sppd",
          authRequired: true,
          role: ["STAF", "KEPALA", "SUPER_ADMIN", "KABID", "KASUB", "DIREKTUR"],
        },
        component: () => import("@/views/history/sppd/Index"),
      },
    ],
  },
  {
    path: "/rekap",
    name: "Rekap",
    meta: {
      title: "rekap",
      authRequired: true,
      role: ["KEPALA", "KASUB", "KABID", "SUPER_ADMIN", "DIREKTUR"],
    },
    redirect: "/rekap/presensi",
    children: [
      {
        path: "presensi",
        name: "RekapPresensi",
        meta: {
          title: "Rekap Presensi",
          authRequired: true,
          role: ["KEPALA", "KASUB", "KABID", "SUPER_ADMIN", "DIREKTUR"],
        },
        component: () => import("@/views/rekap/presensi/Index"),
      },
    ],
  },
  {
    path: "/profil",
    name: "Profile",
    meta: {
      title: "Profil",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF", "KEPALA", "KABID", "KASUB", "DIREKTUR"],
    },
    component: () => import("@/views/profil/Index"),
  },

  /**
   * WITHOUT LOGIN
   */
  {
    path: "/kode-red-blue/open",
    name: "cdRedBlue",
    meta: {
      title: "Kode Red&Blue RS",
      authRequired: false,
    },
    component: () => import("@/views/jadwal_code/ListOpen"),
  },
];
