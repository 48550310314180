<template>
    <div>
        <transition name="slide-bottom" mode="out-in">
            <v-spinner></v-spinner>
        </transition>
        <router-view></router-view>
        <RingBottomNavigation
            v-if="mobileScreen && auth"
            border-color="#69000d"
            title-color="#00695c"
            badge-color="#B52C2C"
            icon-color="#8C8C8C"
            :options="options"
            v-model="selected"
        />
    </div>
</template>

<script>
import { RingBottomNavigation } from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
import VSpinner from "@/components/v-spinner";
// import { messaging } from "./config/firebaseToken";
import { getCurrentInstance } from "vue";

export default {
    name: "App",
    components: {
        RingBottomNavigation,
        VSpinner,
    },
    //   provide() {
    //     return {
    //       message: this.message,
    //     };
    //   },
    //   mounted() {
    //     console.log(
    //       "Firebase cloud messaging object",
    //       this.app.appContext.config.globalProperties.messaging
    //     );
    //   },
    data: () => ({
        selected: 1,
        mobileScreen: false,
        options: [
            {
                id: 1,
                icon: "ri-home-3-line",
                title: "Home",
                path: { name: "Dashboard" },
            },
            {
                id: 2,
                icon: "ri-user-location-fill",
                title: "Presensi",
                path: { name: "Presensi" },
            },
            {
                id: 3,
                icon: "ri-walk-fill",
                title: "Izin",
                path: { name: "Izinku" },
            },
            {
                id: 4,
                icon: "ri-calendar-event-fill",
                title: "Tukar",
                path: { name: "TukarShift" },
            },
            {
                id: 5,
                icon: "ri-calendar-check-fill",
                title: "Jadwal",
                path: { name: "JadwalList" },
            },
            {
                id: 6,
                icon: "ri-history-fill",
                title: "History",
                path: { name: "HistoryPresensi" },
            },
        ],
        listenersStarted: false,
        idToken: "",
        // messaging,
        app: getCurrentInstance(),
    }),
    created() {
        var windowSize = document.documentElement.clientWidth;
        if (windowSize < 767) {
            this.mobileScreen = true;
        } else {
            this.mobileScreen = false;
        }
    },
    computed: {
        auth() {
            return this.$store.state?.auth?.data?.role !== undefined;
        },
    },
};
</script>
<style>
.rg-btn-container {
    background: #e8f5e9;
}
</style>
